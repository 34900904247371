/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable, Subject } from 'rxjs';
import { UsuarioService } from './datos/usuario.service';
import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root',
})
export class InactividadService {
  timeOut: number = environment.timeOut;
  private timeoutDuration = this.timeOut; // Milisegundos de inactividad
  private userActivityTimeout: any;
  private userActivityEvents = ['mousemove', 'click', 'keydown', 'scroll', 'touchstart'];
  private inactivity$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public usuarioSrv: UsuarioService,
    private modalService: ModalService // Inyección del servicio de modales
  ) {
    this.startWatching();
  }

  // Iniciar monitoreo de actividad
  private startWatching() {
    this.resetTimeout();
    this.userActivityEvents.forEach((eventName) => {
      window.addEventListener(eventName, () => this.resetTimeout());
    });
  }

  // Reiniciar temporizador
  private resetTimeout() {
    if (this.userActivityTimeout) {
      clearTimeout(this.userActivityTimeout);
    }
    this.userActivityTimeout = setTimeout(() => this.handleInactivity(), this.timeoutDuration);
  }

  // Manejar inactividad
  private handleInactivity() {
    this.inactivity$.next(true);
    this.modalService.cerrarTodosLosModalesMaterial(); // Cierra modales de Angular Material 
    this.logout();
  }

  // Logout por inactividad
  private logout() {
    this.usuarioSrv.logOut(); // Método para cerrar sesión
  }

  // Observable para detectar inactividad
  onInactivity(): Observable<boolean> {
    return this.inactivity$.asObservable();
  }
}
