/* eslint-disable @typescript-eslint/no-explicit-any */
import { OnInit } from '@angular/core';
// core
import { DatePipe, CommonModule } from '@angular/common';
import { Component, LOCALE_ID, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

// material
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MaterialModule } from 'app/auth/material/material.module';

// componentes
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { FeatherIconsComponent } from '@shared/components/feather-icons/feather-icons.component';
import { EntradaDialogComponent } from './dialogs/entrada-dialog/entrada-dialog.component';

//servicios
import { AlmacenesService } from 'app/services/datos/almacenes.service';
import { UsuarioService } from 'app/services/datos/usuario.service';
import { NotificacionesService } from 'app/services/notificaciones.service';
import { EntradasService } from 'app/services/datos/entradas.service';

//pipes
import { PedidoEstadoPipe } from 'app/pipes/pedido-estado.pipe';

//interfaces 
import { IListaOpciones } from 'app/interfaces/lista-opciones';

// librerías
import { UnsubscribeOnDestroyAdapter, TableElement, TableExportUtil } from '@shared';
import Swal from 'sweetalert2';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { IApiEntrada } from './IApiEntrada';
import { CustomPaginatorIntl } from 'app/services/custom-paginator.service';


@Component({
  selector: 'app-entradas',
  standalone: true,
  providers: [DatePipe, { provide: LOCALE_ID, useValue: 'es' }, { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl}],
  imports: [CommonModule, FormsModule, BreadcrumbComponent, MaterialModule, MatTableModule, FeatherIconsComponent,
    MatSortModule, MatPaginatorModule, MatFormFieldModule, MatInputModule, PedidoEstadoPipe],
  templateUrl: './entradas.component.html',
  styleUrl: './entradas.component.scss'
})
export class EntradasComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  // configuracion de la tabla
  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  // Decoradores para ordenar y paginación
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  // Intervalo de refresco de la tabla
  refreshInterval: any;
  timeRefresh: number = environment.timeRefreshEntradas;

  public imgSubs: Subscription | undefined;
  public fotoDir: string = environment.fotoDir;
  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';

  breadscrums = [{ title: 'Preparación de entradas', items: ['Inicio'], active: 'Listado de entradas pendientes', }];
  loading: boolean = false;
  opcionesList: IListaOpciones = {}

  displayedColumns: string[] = ['id', 'created_at', 'albaran', 'proveedor', 'totCantidad', 'pales', 'devolucion', 'actions'];
  searchableColumns: string[] = ['id', 'created_at', 'albaran', 'proveedor'];
  dataSource = new MatTableDataSource<IApiEntrada>([]);

  constructor(
    private entradasSrv: EntradasService,
    public almacenesSrv: AlmacenesService,
    private usuarioSrv: UsuarioService,
    private notificSrv: NotificacionesService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
  ) {
    super();
  }

  ngOnInit(): void {

    this.getListaEntradas();

    // Configurar un intervalo para refrescar datos auomáticamente 
    this.refreshInterval = setInterval(() => {
      if (this.dataSource.data.length > 0) {
        this.getListaEntradas();  // refrescar datos solo si hay datos en pantalla
      }
    }, this.timeRefresh); // tiempo para el Refesco automático de datos 

  }


  override ngOnDestroy(): void {
    // Limpiar el intervalo cuando el componente se destruya
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }




  getListaEntradas() {
    this.opcionesList.id_cliente = this.usuarioSrv.userdata.cliente!.id;
    this.opcionesList.swValidado = '1';
    this.opcionesList.id_almacen = this.usuarioSrv.userdata.cliente!.alm_por_defecto;

    this.loading = true;
    this.entradasSrv.getLista(this.opcionesList)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje);
          return
        }

        this.dataSource.data = resp.data;
        console.log('__getListaEntradas() ', resp)
      })
  }


  // Buscar 
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if (this.searchableColumns && this.searchableColumns.length > 0) {
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        const searchableData = this.searchableColumns.map(campo => data[campo]).join(' ');
        return searchableData.toLowerCase().includes(filter);
      };
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }



  highlightSearch(text: string, searchTerm: string): string {

    if (text == null) return '';
    if (!searchTerm) return text;

    const regex = new RegExp(searchTerm, 'gi');
    const highlightedText = text.replace(regex, match => `<span class="resaltado">${match}</span>`);
    return highlightedText;
  }


  transformDate(date: string | null): string {
    //  return date !== null ? this.datePipe.transform(date, 'shortDate') ?? '' : '';    
    if (!date) return '';
    return date !== null ? this.datePipe.transform(date, 'yyyy-MM-dd') ?? '' : '';
  }




  //  Diálogos CRUD  //


  // Editar 
  openDialogEntradaDetall(row: IApiEntrada) {
    console.log('__openDialogEntradaDetall()', row)

    const dialogRef = this.dialog.open(EntradaDialogComponent, {
      data: {
        entrada: row,
        action: 'edit',
      }
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
      // After dialog is closed 
      console.log('---> Estoy en entrada-dialog.component, se acaba de cerrar el modal action: -->EDIT<-- result:', result)
      this.getListaEntradas();
    });
  }


  // Añadir
  openDialogNew() {
    const dialogRef = this.dialog.open(EntradaDialogComponent, {
      data: {
        action: 'new',
      }
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
      // After dialog is closed 
      console.log('---> Estoy en entrada-dialog.component, se acaba de cerrar el modal action: -->NEW<-- result:', result)
      this.getListaEntradas();
    });
  }


  // Validar Borrar entrada
  deleteRec(entrada: IApiEntrada) {

    console.log('__deleteRec() ', entrada)

    // Validar que aún existe estaentrada y está pendiente

    this.loading = true
    this.entradasSrv.getEntrada(parseInt(entrada.id!)).subscribe(resp => {
      this.loading = false

      console.log('entradasSrv.getEntrada', resp)

      if (resp.error) {
        this.notificSrv.aviso('error', resp.mensaje)
        this.getListaEntradas();
        return
      }
      if (resp.data.swValidado !== '1') {
        this.notificSrv.aviso('error', 'Esta entrada ya ha validado su estado, no se puede borrar')
        this.getListaEntradas();
        return
      }

      // Validaciones OK. se puede borrar

      this.borraValidado(entrada)
    })
  }


  // Borrar entrada
  borraValidado(entrada: IApiEntrada) {
    // Preguntar antes de borrar

    Swal.fire({
      title: 'Borrar entrada',
      icon: 'warning',
      html: `¿Quieres eliminar la entrada con albarán  ${entrada.albaran}?`,
      showCancelButton: true,
      cancelButtonText: 'Volver',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true
        this.entradasSrv.delete(parseInt(entrada.id!)).subscribe(resp => {
          this.loading = false
          console.log('entradasSrv.delete', resp)
          if (resp.error) {
            this.notificSrv.aviso('error', resp.mensaje)
            return
          }

          this.notificSrv.aviso('success', `Entrada ${entrada.albaran} eliminada`)
          this.getListaEntradas();
          return
        })

      }
    })

  }


  /// AUX /// 

  descargaAlbaranClick(entrada: IApiEntrada) {
    console.log(`__descargaAlbaran() ${entrada}`)
    const urlPdf: string = environment.pdfDir + '/albaranEntrada/' + entrada.pdf_albaran;
    window.open(urlPdf, '_blank');
  }


  // export table data in excel file
  exportExcel() {
    const exportData: Partial<TableElement>[] =
      this.dataSource.filteredData.map((x) => ({
        'Id': x.id,
        'Albarán': x.albaran,
      }));

    console.log('exportData', exportData)
    if (!exportData.length) {
      this.notificSrv.aviso('warning', 'No hay datos a exportar en XLSX')
      return
    }
    TableExportUtil.exportToExcel(exportData, 'excel');

  }



  // Totales 

  getTotalCantidad(): number {
    return this.dataSource.filteredData.reduce((total, rec) => {
      const cantidadNum = rec.totCantidad ? parseInt(rec.totCantidad as unknown as string, 10) : 0; // Aserción de tipo con 'as'
      return total + cantidadNum;
    }, 0);

  }



}
