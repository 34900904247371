/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/use-lifecycle-interface */

import { Component, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { CommonModule, DatePipe, formatDate } from '@angular/common';

// componentes 
import { BreadcrumbComponent } from "../shared/components/breadcrumb/breadcrumb.component";

// módulos  
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MaterialModule } from 'app/auth/material/material.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { FormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';

// interfaces 
import { IListaOpciones } from 'app/interfaces/lista-opciones';
import { IApiDataPedido } from './iDataPedido';

// servicios
import { PedidosService } from 'app/services/datos/pedidos.service';
import { UsuarioService } from 'app/services/datos/usuario.service';
import { AlmacenesService } from 'app/services/datos/almacenes.service';
import { NotificacionesService } from 'app/services/notificaciones.service';

// librerías
import { TableElement, TableExportUtil } from '@shared';
import { PedidoEstadoPipe } from 'app/pipes/pedido-estado.pipe';
import { MatDialog } from '@angular/material/dialog';
import { FeatherIconsComponent } from '@shared/components/feather-icons/feather-icons.component';
import { environment } from 'environments/environment';
import { PedidoDetallDialogComponent } from './dialogs/pedido-detall-dialog/pedido-detall-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomPaginatorIntl } from 'app/services/custom-paginator.service';



@Component({
  selector: 'app-pedidos',
  standalone: true, templateUrl: './pedidos.component.html',
  styleUrl: './pedidos.component.scss',
  providers: [DatePipe, { provide: LOCALE_ID, useValue: 'es' }, { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }],
  imports: [CommonModule, FormsModule, BreadcrumbComponent, MaterialModule, MatTableModule, FeatherIconsComponent,
    MatSortModule, MatPaginatorModule, MatFormFieldModule, MatInputModule,
    PedidoEstadoPipe]
})
export class PedidosComponent {

  // configuracion de la tabla
  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  // Decoradores para ordenar y paginación
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    // this.dataSource.filter = 'naa-002';

    console.log('__Paginator:', this.paginator);
    console.log('__Sort:', this.sort);
  }

  // Intervalo de refresco de la tabla
  refreshInterval: any;
  timeRefreshPedidos: number = environment.timeRefreshPedidos;

  private pdfDir: string = environment.pdfDir;

  breadscrums = [{ title: 'Consulta de pedidos', items: ['Inicio'], active: 'Pedidos', }];
  loading: boolean = false;
  opcionesList: IListaOpciones = {}

  dataSource = new MatTableDataSource<IApiDataPedido>([]);
  displayedColumns: string[] = ['id', 'pedido_cli', 'fecha_pedidoExterno', 'camp_cod', 'tot_articulos', 'tot_referencias', 'nombre', 'poblacion', 'pais_iso', 'cod_servicio', 'estado', 'actions'];
  searchableColumns: string[] = ['id', 'pedido_cli', 'fecha_pedidoExterno', 'camp_cod', 'nombre', 'cp', 'poblacion', 'pais_iso', 'cod_servicio', 'estado'];


  constructor(
    private pedidosSrv: PedidosService,
    public almacenesSrv: AlmacenesService,
    private usuarioSrv: UsuarioService,
    private notificSrv: NotificacionesService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    // Suscribirse a los parámetros de la url, si envían una id, cargar los datos del articulo
    this.activatedRoute.params.subscribe(({ buscar }) => {
      this.dataSource.filter = buscar;
    });

    this.getListaPedidos();

  }



  getListaPedidos() {
    this.opcionesList.id_cliente = this.usuarioSrv.userdata.cliente!.id;

    this.loading = true;
    this.pedidosSrv.getLista(this.opcionesList)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje);
          return
        }

        this.dataSource.data = resp.data;
        console.log('__getListaPedidos() ', resp)
      })
  }


  // Buscar 
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if (this.searchableColumns && this.searchableColumns.length > 0) {
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        const searchableData = this.searchableColumns.map(campo => data[campo]).join(' ');
        return searchableData.toLowerCase().includes(filter);
      };
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }


  highlightSearch(text: string, searchTerm: string): string {

    if (text == null) return '';
    if (!searchTerm) return text;

    const regex = new RegExp(searchTerm, 'gi');
    const highlightedText = text.replace(regex, match => `<span class="resaltado">${match}</span>`);
    return highlightedText;
  }


  // Totales
  getTotalReferencias(): number {
    return this.dataSource.filteredData.reduce((total, rec) => {
      const cantidadNum = rec.tot_referencias! || 0;      // Convertir la cantidad a un número antes de sumar
      return total + cantidadNum;
    }, 0);
  }

  getTotalArticulos(): number {
    return this.dataSource.filteredData.reduce((total, rec) => {
      const cantidadNum = rec.tot_articulos! || 0;      // Convertir la cantidad a un número antes de sumar
      return total + cantidadNum;
    }, 0);

  }


  descargaAlbaranClick(id_pedido: number) {
    console.log('__descargaAlbaranClick() id_pedido=', id_pedido);
    this.loading = true;
    this.pedidosSrv.downloadAlbaran(id_pedido)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje || 'Error desconocido');
          return
        }
        const urlPdf: string = environment.pdfDir + '/' + resp.data;
        window.open(urlPdf, '_blank');
      })
  }

  mostrarTraking(id_pedido: number) {
    const urlTraking: string = 'http://www.gls.com/Esto_es_la_URL_de_tracking.com';
    window.open(urlTraking, '_blank');
  }


  // Diálogos

  openDialogPedidoDetall(row: IApiDataPedido) {
    console.log('__openDialogPedidoDetall()', row)

    const dialogRef = this.dialog.open(PedidoDetallDialogComponent, {
      data: {
        pedido: row,
        action: 'edit',
      }
    });
  }


  openDialogNew() {
    // const dialogRef = this.dialog.open(PedidoNewDialogComponent, {
    //   data: {
    //     action: 'new',
    //   }
    // });
  }



  /// AUX ///

  // export table data in excel file completo, cabeceras se repiten en cada línea
  exportExcel_Beta1() {
    const newArray = (this.dataSource.filteredData as any[]).flatMap((item) => {
      return item.detall.map((detalle: any) => {
        return {
          // Cabecera
          id_pedido: item.id,
          fecha_entrada: item.fecha_entrada,
          camp_cod: item.camp_cod,
          pedido_cli: item.pedido_cli,

          // Detall
          artSku: detalle.artSku,
          artEan: detalle.artEan,
          artDescripcion: detalle.artDescripcion,
          cantidad: detalle.cantidad,
          cant_repartir: detalle.cant_repartir,
          cant_repartida: detalle.cant_repartida
        };
      });
    });

    console.log(newArray)
  }


  // export table data in excel file completo, LAS cabeceras se repiten en cada línea
  exportExcel() {
    const exportData: Partial<TableElement>[] =
      (this.dataSource.filteredData as any[]).flatMap((item) => {
        return item.detall.map((detalle: any) => {
          return {
            // Cabecera
            'id pedido': item.id,
            'Fecha entrada': formatDate(new Date(item.fecha_entrada!), 'dd/MM/yyyy HH:mm', 'en') || '',
            'Campaña': item.camp_cod,
            'Pedido': item.pedido_cli,

            // Detall
            'Referencia': detalle.artSku,
            'EAN': detalle.artEan,
            'Descripcion': detalle.artDescripcion,
            'cantidad': detalle.cantidad,
            'cant_repartir': detalle.cant_repartir,
            'cant_repartida': detalle.cant_repartida
          };
        });
      });

    console.log(exportData)
    if (!exportData.length) {
      this.notificSrv.aviso('warning', 'No hay datos a exportar en XLSX')
      return
    }
    TableExportUtil.exportToExcel(exportData, 'excel');
  }





}