<div class="addContainer">

  <div class="modalHeader">
    <div class="editRowModal">
      <div class="modalHeader clearfix">
        <img src='../../../../assets/images/grid.svg'
             alt="Solicitud de pedido InnovaCen">
        <div class="modal-about">
          <span class="text-success me-2">Solicitud {{dialogTitle}}</span>
          <small>{{pedido.created_at | date: 'MM/dd/yyyy'}}</small>
        </div>
      </div>
    </div>
    <button mat-icon-button
            (click)="cancelarClick()"
            class="modal-close-button"
            aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <form class="m-1"
          [formGroup]="cabeceraForm"
          #formularioCabecera
          (ngSubmit)="enviar()">

      <div class="row">

        <!-- pedido_cli -->
        <div class="col-2">
          <mat-form-field class="example-full-width mb-1"
                          appearance="outline">
            <mat-label>Pedido</mat-label>
            <input matInput
                   type="text"
                   formControlName="pedido_cli"
                   (blur)="PedidoCliBlur()"
                   maxlength="30">
            <mat-error *ngIf="f['pedido_cli'].hasError('required')">Oligatorio</mat-error>
            <mat-error *ngIf="f['pedido_cli'].hasError('minlength')">Mínimo 4 caracteres</mat-error>

            <mat-error *ngIf="cabeceraForm.get('pedido_cli')?.errors?.['apiError']">{{
              cabeceraForm.get('pedido_cli')?.errors?.['apiError'] }}</mat-error>

          </mat-form-field>
        </div>


        <!-- nombre -->
        <div class="col-3">
          <mat-form-field class="example-full-width"
                          appearance="outline">
            <mat-label>Nombre y apellidos</mat-label>
            <input matInput
                   type="text"
                   formControlName="nombre"
                   maxlength="150">
            <mat-error *ngIf="f['nombre'].hasError('minlength')">Mínimo 4 caracteres</mat-error>
          </mat-form-field>
        </div>


        <!-- email -->
        <div class="col-3">
          <mat-form-field class="example-full-width"
                          appearance="outline">
            <mat-label>eMail</mat-label>
            <input matInput
                   formControlName="email">
            <mat-icon matSuffix
                      class="material-icons-outlined color-icon p-3">email</mat-icon>

            <mat-error *ngIf="f['email'].hasError('eMailFormat')">No parece un eMail válido</mat-error>

            <mat-error *ngIf="cabeceraForm.get('email')?.errors?.['apiError']">{{
              cabeceraForm.get('email')?.errors?.['apiError'] }}</mat-error>

          </mat-form-field>
        </div>


        <!-- telefono -->
        <div class="col-2">
          <mat-form-field class="example-full-width"
                          appearance="outline">
            <mat-label>Teléfono</mat-label>
            <input matInput
                   type="text"
                   formControlName="telefono"
                   maxlength="20">

            <mat-error *ngIf="cabeceraForm.get('telefono')?.errors?.['apiError']">{{
              cabeceraForm.get('telefono')?.errors?.['apiError'] }}</mat-error>

          </mat-form-field>
        </div>



        <!-- contrarrembolso -->
        <div class="col-2">
          <mat-form-field class="example-full-width"
                          appearance="outline">
            <mat-label>Contrarrembolso</mat-label>
            <input matInput
                   formControlName="contrarrembolso"
                   type='text'
                   mask="separator"
                   [allowNegativeNumbers]="false"
                   style="text-align: right;">
            <mat-icon matSuffix
                      class="material-icons-outlined color-icon p-3">euro</mat-icon>
          </mat-form-field>
        </div>


        <!-- direccion -->
        <div class="col-4">
          <mat-form-field class="example-full-width"
                          appearance="outline">
            <mat-label>Dirección</mat-label>
            <input matInput
                   type="text"
                   formControlName="direccion"
                   maxlength="255">
          </mat-form-field>
        </div>

        <!-- cp -->
        <div class="col-1">
          <mat-form-field class="example-full-width"
                          appearance="outline">
            <mat-label>CP</mat-label>
            <input matInput
                   type="text"
                   formControlName="cp"
                   maxlength="5">
            <mat-error *ngIf="f['cp'].hasError('numEnteroCincoDigitos')">5 Dígitos</mat-error>
          </mat-form-field>
        </div>

        <!-- poblacion -->
        <div class="col-3">
          <mat-form-field class="example-full-width"
                          appearance="outline">
            <mat-label>Población</mat-label>
            <input matInput
                   type="text"
                   formControlName="poblacion"
                   maxlength="150">
          </mat-form-field>
        </div>


        <!-- pais_iso -->
        <div class="col-2">
          <mat-form-field class="example-full-width "
                          appearance="outline">
            <mat-label>País</mat-label>
            <mat-select formControlName="pais_iso"
                        required>
              <mat-option *ngFor="let pais of paisesList"
                          [value]="pais.iso">
                {{ pais.nombre }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- cod_servicio -->
        <div class="col-2">
          <mat-form-field class="example-full-width "
                          appearance="outline">
            <mat-label>Envío</mat-label>
            <mat-select formControlName="cod_servicio"
                        required>
              <mat-option *ngFor="let servicio of serviciosList"
                          [value]="servicio.codServicio">
                {{ servicio.nombre }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>


      </div> <!-- row -->




      <div class="row">

        <!-- Botones -->
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">

          <div class="example-button-row">
            <button mat-raised-button
                    color="primary"
                    type="submit"
                    [disabled]="!cabeceraForm.valid"
                    [disabled]="loading">
              {{txtSubmit}}
            </button>

            <button mat-raised-button
                    type="button"
                    color="warn"
                    (click)="cancelarClick()"
                    [disabled]="loading"
                    [mat-dialog-close]="1"
                    tabindex="-1">
              Cancelar
            </button>

            <div class="right">
              <ul class="tbl-export-btn">
                <li class="tbl-header-btn">

                  <div class="m-l-10"
                       matTooltip="Añadir línea de artículo"
                       style="display: flex; align-items: center;">

                    <button mat-mini-fab
                            type="button"
                            color="primary"
                            (click)="addLineaDetall()"
                            [disabled]="loading">
                      <mat-icon class="col-white">add</mat-icon>
                    </button>

                    <span class="mb-1 badge"
                          [ngClass]="{'badge-solid-green': arrayDetalleForm.length > 0, 'badge-solid-orange': arrayDetalleForm.length === 0}">
                      {{arrayDetalleForm.length}} artículos
                    </span>

                  </div>

                </li>
              </ul>
            </div>



          </div>
        </div>

      </div> <!-- row -->



    </form>





    <!-- Formularios de Detalle sobre arrayDetalleForm -->
    <form *ngFor="let detalleForm of arrayDetalleForm; let i = index"
          [formGroup]="detalleForm"
          #formularioDetalle>


      <div class="row">


        <!-- id_articulo -->
        <div class=" col-md-8 ">
          <div id="ngSelectContainer">

            <ng-select class="example-full-width "
                       [class.error]="enviado && fd(i, 'id_articulo')?.invalid"
                       formControlName="id_articulo"
                       appearance="outline"
                       [searchable]="true"
                       [clearable]="true"
                       dropdownPosition="top"
                       labelForId="id_articulo"
                       (change)="cambiaArticulo($event, i)"
                       placeholder="Artículo {{i+1}}"
                       [items]="articulosList"
                       bindLabel="label txt"
                       bindValue="id"
                       [searchFn]="customSearchFnArticulo"
                       notFoundText="Ningún artículo encontrado"
                       clearAllText="Clear"
                       required>

              <ng-template ng-label-tmp
                           let-item="item">
                <span *ngIf="item.descripcion">{{ item.ean + ' (' + item.descripcion + ')' }}</span>
              </ng-template>

              <ng-template ng-option-tmp
                           let-item="item"
                           let-search="searchTerm"
                           let-index="index">
                <span [ngOptionHighlight]="search">{{ item.ean + ' - ' + item.sku + ' (' + item.descripcion + ')' }}</span>
              </ng-template>

            </ng-select>

          </div>
        </div>


        <!-- foto -->
        <div class="col-md-1 table-img ">
          <div *ngIf="fd(i, 'artFoto')?.value"
               class="mt-4">
            <img [src]="fotoDir +'/articulos' + imgResized +fd(i, 'artFoto')?.value"
                 loading="lazy" />
          </div>
        </div>



        <!-- cantidad -->
        <div class=" col-md-1">
          <mat-form-field class="example-full-width"
                          appearance="outline">
            <mat-label>Cantidad</mat-label>
            <input matInput
                   type="text"
                   class="text-right"
                   mask="separator"
                   formControlName="cantidad">
            <mat-error *ngIf="fd(i, 'cantidad')?.hasError('numEntero')">Debe ser un entero positivo</mat-error>
          </mat-form-field>
        </div>




        <!-- stockTot -->
        <div class="col-md-1">
          <mat-form-field class="example-full-width"
                          appearance="outline">
            <mat-label>Stock</mat-label>
            <input matInput
                   [ngClass]="{'customWarning': isArtStockLessThanCantidad(i)}"
                   type="text"
                   class="text-right"
                   mask="separator"
                   readonly="true"
                   formControlName="stockTot">
          </mat-form-field>
        </div>


        <!-- Eliminar la línea -->
        <div class="col-1">
          <button mat-icon-button
                  (click)="$event.stopPropagation()"
                  (click)="deleteLineaDetall(i)"
                  [disabled]="loading"
                  class="tbl-action-btn">
            <app-feather-icons [icon]="'trash-2'"
                               [class]="'tbl-fav-delete'"></app-feather-icons>
          </button>
        </div>


      </div><!-- row -->

    </form>


  </div>
</div>