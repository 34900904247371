/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder, Validators, NgForm, AbstractControl, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';

// módulos
import { MAT_DATE_LOCALE, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MaterialModule } from 'app/auth/material/material.module';

//interfaces
import { IDataPedidoExterno, IDataPedidoExternoDetall } from 'app/pedidos-ext/IDataPedidoExterno';
import { IPais } from 'app/interfaces/paises';
import { IServicio } from 'app/interfaces/servicios';
import { IListaOpciones } from 'app/interfaces/lista-opciones';

//servicios
import { DatosAuxService } from 'app/services/datos/datosAux.service';
import { NotificacionesService } from 'app/services/notificaciones.service';
import { PedidosExternosService } from 'app/services/datos/pedidosExternos.service';
import { ArticulosService } from 'app/services/datos/articulos.service';
import { UsuarioService } from './../../../services/datos/usuario.service';


//librerías
import CustomVal from 'app/providers/CustomValidators';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { FeatherIconsComponent } from "../../../shared/components/feather-icons/feather-icons.component";
import Swal from 'sweetalert2';
import { environment } from 'environments/environment';
import { IArticulo } from 'app/interfaces/articulo';
import { id } from 'date-fns/locale';



export interface IDialogData {
  id: number;
  action: string;
  pedido: IDataPedidoExterno;   // Pedido externo vitaminado, en formato API
}


@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-ext-dialog.component.html',
  styleUrls: ['./form-ext-dialog.component.scss'],
  standalone: true,
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    provideNgxMask()
  ],
  imports: [CommonModule, MaterialModule, MatAutocompleteModule, MatIconModule, MatDialogContent, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule,
    MatRadioModule, MatDatepickerModule, MatSelectModule, MatOptionModule, MatDialogClose, NgxMaskDirective, NgxMatSelectSearchModule,
    NgSelectModule, NgOptionHighlightModule, FeatherIconsComponent]
})
export class FormExtDialogComponent implements OnInit {

  @ViewChild('formularioCabecera') formularioCabecera: NgForm | undefined;
  @ViewChild('formularioDetalle') formularioDetalle: NgForm[] | undefined;


  private action: string;
  private id_cliente: number | null = null

  dialogTitle: string;
  txtSubmit: string = 'Modificar';

  public fotoDir: string = environment.fotoDir;
  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';

  public paisesList: IPais[] = [];
  public serviciosList: IServicio[] = [];
  public articulosList: IDataPedidoExternoDetall[] = [];

  public pedido: IDataPedidoExterno = {};
  public arrayDetalleForm: FormGroup[] = []; // Array de formularios de detalle  

  public cabeceraForm: FormGroup = new FormGroup({});
  public detallForm: FormGroup = new FormGroup({});

  public loading: boolean = false;
  public enviado: boolean = false;


  constructor(
    public dialogRef: MatDialogRef<FormExtDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,

    private fb: FormBuilder,
    public userSrv: UsuarioService,
    public datosAuxSrv: DatosAuxService,
    private notificSrv: NotificacionesService,
    private pedidoExtSrv: PedidosExternosService,
    private articulosSrv: ArticulosService,

  ) {
    // Set Valores por defecto

    this.id_cliente = this.userSrv.userdata.cliente!.id,


      this.action = data.action;
    if (this.action === 'edit') {
      this.dialogTitle = data.pedido.pedido_cli!;
      this.txtSubmit = 'Modificar';
      this.pedido = data.pedido;
    } else {
      this.dialogTitle = 'Nuevo pedido';
      this.txtSubmit = 'Crear';
      this.pedido = {
        id_cliente: this.id_cliente.toString(),
        fecha_pedido: new Date(),
        pais_iso: 'ES',
        cod_servicio: this.userSrv.userdata.cliente?.codServicio_pred,
        contrarrembolso: '0.00'
      }
      this.addLineaDetall()   // Añadir una línea en blanco
    }

  }


  ngOnInit(): void {
    // Definir formularios

    this.cabeceraForm = this.fb.group({
      id_cliente: [''],
      pedido_cli: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(30)]],
      fecha_pedido: [new Date(), Validators.required],
      nombre: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(150)]],
      direccion: ['', Validators.required],
      cp: ['', [Validators.required, CustomVal.esEnteroDeCincoDigitos]],
      poblacion: ['', Validators.required],
      pais_iso: ['', Validators.required],
      telefono: [''],
      email: ['', [CustomVal.ValidateEmail]],
      contrarrembolso: [''],
      cod_servicio: [this.userSrv.userdata.cliente?.codServicio_pred, Validators.required],
    });

    // Iterar sobre los detalles y crear un formulario para cada detall
    const pedidoDetall = this.pedido.detall || [];

    pedidoDetall.forEach(detalle => {
      const detalleFormGroup = this.fb.group({
        id_articulo: new FormControl(detalle.id_articulo, Validators.required),
        artFoto: new FormControl(detalle.artFoto, Validators.required),
        cantidad: new FormControl(detalle.cantidad, [Validators.required, CustomVal.esEntero]),
        stockTot: new FormControl({ value: detalle.stockTot, disabled: true }),
      });

      this.arrayDetalleForm.push(detalleFormGroup);
    });

    this.getListaPaises();
    this.getListaServiciosCliente();
    this.getListaArticulos();
    this.cargaDatosPedido();


    // Escuchar cambios en el campo de país_iso para Restablecer errores en los campos de eMail y teléfono
    this.cabeceraForm.get('pais_iso')?.valueChanges.subscribe(() => {
      this.cabeceraForm.get('email')?.setErrors(null);
      this.cabeceraForm.get('telefono')?.setErrors(null);
    });

  }

  // Carga los datos del pedido en el formulario
  cargaDatosPedido() {
    console.log('__cargaDatosPedido()', this.pedido)
    this.cabeceraForm.patchValue({
      id_cliente: this.pedido.id_cliente,
      pedido_cli: this.pedido.pedido_cli,
      fecha_pedido: this.pedido.fecha_pedido,
      nombre: this.pedido.nombre,
      direccion: this.pedido.direccion,
      cp: this.pedido.cp,
      poblacion: this.pedido.poblacion,
      pais_iso: this.pedido.pais_iso,
      telefono: this.pedido.telefono,
      email: this.pedido.email,
      contrarrembolso: this.pedido.contrarrembolso,
      cod_servicio: this.pedido.cod_servicio,
    })
  }



  getListaPaises() {
    this.loading = true
    this.datosAuxSrv.getListaPaises()
      .subscribe(resp => {
        this.loading = false
        console.log('__getListaPaises()', resp)
        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje);
          return
        }
        this.paisesList = resp.data;
      })
  }


  getListaServiciosCliente() {
    this.loading = true
    this.datosAuxSrv.getListaServiciosCliente(this.userSrv.userdata.cliente!.id)
      .subscribe(resp => {
        this.loading = false
        console.log('__getListaServiciosCliente() resp', resp)

        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje);
          return
        }
        this.serviciosList = resp.data;
      })
  }


  getListaArticulos() {
    const opciones: IListaOpciones = {
      id_cliente: this.id_cliente
    }
    this.loading = true
    this.articulosSrv.getListaFiltrada(opciones)
      .subscribe(resp => {
        this.loading = false
        console.log('__getListaArticulos()', resp)
        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje);
          return
        }
        this.articulosList = resp.data;
      })
  }




  enviar() {
    this.enviado = true;

    // Marcar todos los controles de todos los formularios de detalle como touched
    this.arrayDetalleForm.forEach(formularioDetalle => {
      Object.keys(formularioDetalle.controls).forEach(controlName => {
        formularioDetalle.controls[controlName].markAsTouched();
      });
    });

    // Validar cabecera  
    if (this.cabeceraForm.invalid) {
      console.log("ERRORES en form cabecera:", this.cabeceraForm);
      return;
    }


    // Validar detall  
    if (!this.arrayDetalleForm.length) {
      this.notificSrv.aviso('warning', `Es necesario añadir un artículo como mínimo `);
      return;
    }

    for (let i = 0; i < this.arrayDetalleForm!.length; i++) {
      if (!this.arrayDetalleForm![i].valid) {
        console.log(`ERRORES en form detall: ${i}`, this.arrayDetalleForm![i]);
        this.notificSrv.aviso('error', `Errores en el artículo ${i + 1} `);
        return;
      }
    }


    // Ambos formularios son válidos 

    //Enviar datos de la cabecera y detalles al Back
    const cabeceraData = this.cabeceraForm.value;
    const detalleData = this.arrayDetalleForm.map(detalleForm => detalleForm.value);
    if (this.pedido.id) cabeceraData.id = this.pedido.id;

    const dataToSend = {
      cabecera: cabeceraData,
      detalle: detalleData,
      action: this.action,
    };

    dataToSend.detalle.forEach(detalle => {
      // no eviar las fotos
      delete detalle.artFoto;
    });

    console.log("__enviar() ", dataToSend);


    //Guardar pedido externo  

    this.loading = true;
    this.pedidoExtSrv.new(dataToSend)
      .subscribe(resp => {
        this.loading = false;

        if (resp.error) {
          console.log('__.pedidoExtSrv.new(dataToSend)', resp);

          // Dejar los errores sin el prefijo 'cabecera.'
          const erroresPlanos: { [key: string]: string } = {};
          for (const key in resp.mensaje) {
            const value = resp.mensaje[key];
            const erroresPlanosKey = key.replace('cabecera.', '');
            erroresPlanos[erroresPlanosKey] = value;
          }

          // Marcar los capos con error
          for (const controlName in erroresPlanos) {
            this.cabeceraForm.get(controlName)!.setErrors({ 'apiError': erroresPlanos[controlName] });
          }

          if ('cabecera.pedido_cli' in resp.mensaje) {
            this.notificSrv.aviso('error', 'No se permiten duplicados, pero puedes añadir un sufijo al pedido. por ejemplo: __1 __2 etc...')
          }

        } else {
          if (this.action === 'new') this.notificSrv.aviso('success', `Pedido ${this.pedido.pedido_cli} creado correctamente con el <b>id: ${resp.data.id}</b>`);
          if (this.action === 'edit') this.notificSrv.aviso('success', `Pedido ${this.pedido.pedido_cli} modificado correctamente.`);

          // cerrar dialogo y actalizar lista
          this.dialogRef.close();
          return
        }
      })
  }



  cancelarClick() {
    this.dialogRef.close();
    return
  }

  deleteLineaDetall(index: number) {
    console.log('__eliminarLinea()', this.arrayDetalleForm[index])

    // si no hay cantidad, no pedir confirmación
    if (this.arrayDetalleForm[index].value.cantidad === '') {
      this.arrayDetalleForm.splice(index, 1);
      return
    }

    Swal.fire({
      title: 'Eliminar referencia',
      icon: 'warning',
      html: `¿Quieres eliminar este artículo del pedido?`,
      showCancelButton: true,
      cancelButtonText: 'Volver',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.arrayDetalleForm.splice(index, 1);
      }
    })
  }

  addLineaDetall() {
    console.log('__addLineaDetall() arrayDetalleForm: ', this.arrayDetalleForm)
    const nuevoDetalleForm = this.fb.group({
      id_articulo: ['', Validators.required],
      cantidad: ['', [Validators.required, CustomVal.esEntero]],
      artFoto: ['_noFoto.png'],
      stockTot: [{ value: '0', disabled: true }],
    });

    this.arrayDetalleForm.push(nuevoDetalleForm);
  }


  //// AUX    /// 

  get f() {
    return this.cabeceraForm.controls;
  }


  fd(i: number, controlName: string): AbstractControl | null {
    const detalleForm = this.arrayDetalleForm[i];
    return detalleForm.get(controlName);
  }


  cambiaArticulo(event: any, index: number) {

    console.log('__cambiaArticulo() Artículo indice:', index);
    console.log('__cambiaArticulo() Artículo event.stockTot:', event);
    console.log('__cambiaArticulo() arrayDetalleForm[]:', this.arrayDetalleForm);

    const detalleFormGroup = this.arrayDetalleForm[index];
    const idArticuloSeleccionado = event.id;

    let articSel: IArticulo = { 'artStock': 0, 'foto': '_noFoto.png' }
    this.articulosSrv.getArticulo(this.arrayDetalleForm[index].get('id_articulo')?.value)
      .subscribe(resp => {
        if (resp.data) articSel = resp.data;
        detalleFormGroup.get('stockTot')?.setValue(articSel.artStock);
        detalleFormGroup.get('artFoto')?.setValue(articSel.foto);
      })


    // Verificar si el artículo ya existe en el array
    const articuloExistente = this.arrayDetalleForm.some((detalleForm, i) => {
      if (i !== index) {
        const idArticuloActual = detalleForm.get('id_articulo')?.value;
        if (idArticuloActual === idArticuloSeleccionado) {
          this.notificSrv.aviso('warning', `La línea ${i + 1} ya contiene este artículo`)
          console.log('idArticuloSeleccionado:', event)
        }
        return false;
      }
      return true
    });


    // Cambiar el stockTot del formulario 
    detalleFormGroup.get('stockTot')?.setValue(event.stockTot);

  }

  isArtStockLessThanCantidad(index: number): boolean {
    // mirar si cantidad es mayor al Stock actual
    const artStockValue = this.arrayDetalleForm[index]?.get('stockTot')?.value;
    const cantidadValue = this.arrayDetalleForm[index]?.get('cantidad')?.value;

    return artStockValue < cantidadValue;
  }


  // customSearchFnArticulo(term: string, item: any) {
  //   term = term.toLocaleLowerCase();
  //   return item.ean.toLocaleLowerCase().indexOf(term) > -1 ||
  //     item.sku.toLocaleLowerCase().indexOf(term) > -1 ||
  //     item.descripcion.toLocaleLowerCase().indexOf(term) > -1;
  // }

  customSearchFnArticulo(term: string, item: any) {
    // Verifica si term es válido antes de convertirlo a minúsculas
    const lowerTerm = term ? term.toLocaleLowerCase() : '';

    // Verifica que item y sus propiedades sean válidas antes de llamar a toLocaleLowerCase
    const ean = item.ean ? item.ean.toLocaleLowerCase() : '';
    const sku = item.sku ? item.sku.toLocaleLowerCase() : '';
    const descripcion = item.descripcion ? item.descripcion.toLocaleLowerCase() : '';

    // Compara el término de búsqueda con las propiedades de item
    return ean.indexOf(lowerTerm) > -1 ||
      sku.indexOf(lowerTerm) > -1 ||
      descripcion.indexOf(lowerTerm) > -1;
  }


  PedidoCliBlur(): void {
    // Pasar pedido_cli a mayúsculas
    const valor = this.f['pedido_cli'].value
    this.cabeceraForm.patchValue({
      pedido_cli: valor.toUpperCase()
    });
  }



} 