<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar"
         class="sidebar"
         (mouseenter)="mouseHover()"
         (mouseleave)="mouseOut()">
    <!-- Menu -->
    <div class="menu">
      <ng-scrollbar [style.height]="listMaxHeight + 'px'"
                    visibility="hover">
        <ul id="sidebarnav"
            class="list">
          <li class="sidebar-user-panel">
            <div class="user-panel">
              <div class=" image"
                   matTooltip="Cambiar foto de usuario">
                <img [src]="getUserFoto()"
                     (click)="subirFoto()"
                     class="img-circle user-img-circle"
                     alt="Usuario" />
              </div>
            </div>

            <!-- user, rol and name, click a config -->
            <div class="profile-usertitle"
                 (click)="router.navigate(['config'])"
                 matTooltip="Configurar APP">
              <div class="sidebar-userpic-name">{{usuarioSrv.userdata.username}} </div>
              <div class="profile-usertitle-job"
                   [innerHTML]="usuarioSrv.userdata.nombre + ' - ' + (usuarioSrv.userdata.rolIntranet | usuarioRol)"></div>
            </div>
          </li>

          <!-- Top Most level menu -->
          @for (sidebarItem of sidebarItems; track sidebarItem) {
          <li [routerLinkActive]="sidebarItem.submenu.length !== 0 ? 'active' : 'active-top'">
            @if (sidebarItem.groupTitle === true) {
            <div class="header">{{sidebarItem.title }}</div>
            }
            @if (!sidebarItem.groupTitle;) {
            <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null"
               [ngClass]="[sidebarItem.class]"
               (click)="callToggleMenu($event, sidebarItem.submenu.length)"
               class="menu-top">
              <i-feather [name]="sidebarItem.icon"
                         class="sidebarIcon"></i-feather>
              <span class="hide-menu">{{sidebarItem.title }} </span>
            </a>
            }

            <!-- First level menu -->
            @if (sidebarItem.submenu.length > 0) {
            <ul class="ml-menu">
              @for (sidebarSubItem1 of sidebarItem.submenu; track sidebarSubItem1) {
              <li [routerLinkActive]="sidebarSubItem1.submenu.length > 0 ? '' : 'active'">
                <a [routerLink]="sidebarSubItem1.submenu.length > 0 ? null : [sidebarSubItem1.path]"
                   (click)="callToggleMenu($event,sidebarSubItem1.submenu.length)"
                   [ngClass]="[sidebarSubItem1.class]">
                  {{sidebarSubItem1.title | translate}}
                </a>
                <!-- Second level menu -->
                @if (sidebarSubItem1.submenu.length > 0) {
                <ul class="ml-menu-2">
                  @for (sidebarSubItem2 of sidebarSubItem1.submenu; track sidebarSubItem2) {
                  <li [routerLinkActive]="sidebarSubItem2.submenu.length > 0 ? '' : 'active'">
                    <a [routerLink]="sidebarSubItem2.submenu.length > 0 ? null : [sidebarSubItem2.path]"
                       (click)="callToggleMenu($event,sidebarSubItem2.submenu.length)"
                       [ngClass]="[sidebarSubItem2.class]">
                      {{sidebarSubItem2.title | translate}}
                    </a>
                    <!-- Third level menu -->
                    @if (sidebarSubItem2.submenu.length > 0) {
                    <ul class="ml-menu-3">
                      @for (sidebarSubItem3 of sidebarSubItem2.submenu; track sidebarSubItem3) {
                      <li [routerLinkActive]="sidebarSubItem3.submenu.length > 0 ? '' : 'active'">
                        <a [routerLink]="sidebarSubItem3.submenu.length > 0 ? null : [sidebarSubItem3.path]"
                           (click)="callToggleMenu($event,sidebarSubItem3.submenu.length)"
                           [ngClass]="[sidebarSubItem3.class]">
                          {{sidebarSubItem3.title | translate}}
                        </a>
                      </li>
                      }
                    </ul>
                    }
                  </li>
                  }
                </ul>
                }
              </li>
              }
            </ul>
            }
          </li>
          }


          <!-- desconectar-->
          <li class="mt-4">
            <a [routerLink]="['/auth']"
               class="menu-top">
              <i-feather name="log-out"
                         class="text-danger sidebarIcon "></i-feather>
              <span class="hide-menu">Desconectar</span>
            </a>
          </li>



        </ul>
      </ng-scrollbar>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->

</div>