<section class="content">
    <div class="content-block">
        @for (breadscrum of breadscrums; track breadscrum) {
        <div class="block-header">
            <!-- breadcrumb -->
            <app-breadcrumb [title]="breadscrum.title"
                            [items]="breadscrum.items"
                            [active_item]="breadscrum.active">
            </app-breadcrumb>
        </div>
        }
        <div class="row clearfix">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="header">
                        <h2>Parámtros y datos de usuario</h2>
                    </div>
                    <div class="body">

                        <div class=" ps-container ps-theme-default ">

                            <div class="row configCss">



                                <!-- Tema -->
                                <div class="col-sm-12 col-md-4">
                                    <mat-card class="border ">
                                        <mat-card-header class="card_header">
                                            <mat-card-subtitle class="h5">
                                                <mat-icon fontIcon="settings"></mat-icon>
                                                Estilos
                                            </mat-card-subtitle>
                                        </mat-card-header>

                                        <mat-card-content>
                                            <div class="row mt-1">

                                                <!-- Sidebar -->
                                                <div class="col-6">
                                                    <label class="h6">Menú: </label><br>
                                                    <mat-button-toggle-group [value]="isDarkSidebar ? 'dark' : 'light'">
                                                        <mat-button-toggle (click)="lightSidebarBtnClick()"
                                                                           value="light">Claro</mat-button-toggle>
                                                        <mat-button-toggle (click)="darkSidebarBtnClick()"
                                                                           value="dark">Oscuro</mat-button-toggle>
                                                    </mat-button-toggle-group>
                                                </div>

                                                <!-- Theme -->
                                                <div class="col-6">
                                                    <label class="h6">Contenido: </label><br>
                                                    <mat-button-toggle-group class="me-2"
                                                                             [value]="isDarkSidebar ? 'dark' : 'light'">
                                                        <mat-button-toggle (click)="lightThemeBtnClick()"
                                                                           value="light">Claro</mat-button-toggle>
                                                        <mat-button-toggle (click)="darkThemeBtnClick()"
                                                                           value="dark">Oscuro</mat-button-toggle>
                                                    </mat-button-toggle-group>
                                                </div>

                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>




                                <!-- Bg Color -->
                                <div class="col-sm-12 col-md-4">
                                    <mat-card class="border">
                                        <mat-card-header class="card_header">
                                            <mat-card-subtitle>
                                                <mat-icon fontIcon="settings"></mat-icon>
                                                Color
                                            </mat-card-subtitle>
                                        </mat-card-header>
                                        <mat-card-content>

                                            <ul class="choose-theme list-unstyled mb-0 mt-4">
                                                <li data-theme="white"
                                                    [ngClass]="{'active': selectedBgColor === 'white'}"
                                                    (click)="selectTheme('white')">
                                                    <div class="white"></div>
                                                </li>
                                                <li data-theme="black"
                                                    [ngClass]="{'active': selectedBgColor === 'black'}"
                                                    (click)="selectTheme('black')">
                                                    <div class="black"></div>
                                                </li>
                                                <li data-theme="purple"
                                                    [ngClass]="{'active': selectedBgColor === 'purple'}"
                                                    (click)="selectTheme('purple')">
                                                    <div class="purple"></div>
                                                </li>
                                                <li data-theme="orange"
                                                    [ngClass]="{'active': selectedBgColor === 'orange'}"
                                                    (click)="selectTheme('orange')">
                                                    <div class="orange"></div>
                                                </li>
                                                <li data-theme="cyan"
                                                    [ngClass]="{'active': selectedBgColor === 'cyan'}"
                                                    (click)="selectTheme('cyan')">
                                                    <div class="cyan"></div>
                                                </li>
                                                <li data-theme="green"
                                                    [ngClass]="{'active': selectedBgColor === 'green'}"
                                                    (click)="selectTheme('green')">
                                                    <div class="green"></div>
                                                </li>
                                                <li data-theme="blue"
                                                    [ngClass]="{'active': selectedBgColor === 'blue'}"
                                                    (click)="selectTheme('blue')">
                                                    <div class="blue"></div>
                                                </li>
                                            </ul>

                                        </mat-card-content>
                                    </mat-card>
                                </div>






                                <!-- Número de filas en consultas de datos -->
                                <div class="col-sm-12 col-md-4">
                                    <mat-card class="border">
                                        <mat-card-header class="card_header">
                                            <mat-card-subtitle>
                                                <mat-icon fontIcon="settings"></mat-icon>
                                                Filas por defecto en las listas de datos
                                            </mat-card-subtitle>
                                        </mat-card-header>
                                        <mat-card-content class="user-details">

                                            <mat-form-field>
                                                <mat-label>mostrar</mat-label>
                                                <mat-select [(ngModel)]="rowsLimitStr"
                                                            (selectionChange)="rowsLimitChange()">
                                                    <mat-option [value]="'5'">5</mat-option>
                                                    <mat-option [value]="'10'">10</mat-option>
                                                    <mat-option [value]="'25'">25</mat-option>
                                                    <mat-option [value]="'100'">100</mat-option>
                                                    <mat-option [value]="'999999'">Todo</mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                        </mat-card-content>

                                    </mat-card>
                                </div>










                                <!-- Datos de usuario -->
                                <div class="col-sm-12 col-md-4">
                                    <mat-card class="border">
                                        <mat-card-header class="card_header">
                                            <mat-card-subtitle>
                                                <mat-icon fontIcon="face"></mat-icon>
                                                Datos de Usuario
                                            </mat-card-subtitle>
                                        </mat-card-header>
                                        <mat-card-content class="user-details">
                                            <ul>
                                                <li>
                                                    <strong>Usuario:</strong>
                                                    <span>{{ userdata.username }}</span>
                                                </li>
                                                <li>
                                                    <strong>Nombre:</strong>
                                                    <span>{{ userdata.nombre }}</span>
                                                </li>
                                                <li>
                                                    <strong>eMail:</strong>
                                                    <span>{{ userdata.email }}</span>
                                                </li>
                                                <li>
                                                    <strong>Teléfono:</strong>
                                                    <span>{{ userdata.telefono }}</span>
                                                </li>
                                            </ul>

                                            <!-- Botón a la derecha -->
                                            <div class="text-end mt-3">                                             
                                                <app-cambiar-psw [loading]="loading"></app-cambiar-psw>
                                            </div>

                                        </mat-card-content>

                                    </mat-card>
                                </div>



                                <!-- Datos de cliente -->
                                <div class="col-sm-12 col-md-8">
                                    <mat-card class="border">
                                        <mat-card-header class="card_header">
                                            <mat-card-subtitle>
                                                <mat-icon fontIcon="apartment"></mat-icon>
                                                Datos de cliente
                                            </mat-card-subtitle>
                                        </mat-card-header>
                                        <mat-card-content class="user-details">

                                            <div class="modalHeader clearfix">

                                                <img [src]="fotoDir +'/clientes' + imgResized + userdata.cliente?.foto"
                                                     alt="logo del albarán"
                                                     matTooltip="Imagen en los Albaranes">

                                            </div>
                                            <div style="display: flex; justify-content: space-between;">
                                                <div style="width: 48%;">
                                                    <ul>
                                                        <li>
                                                            <strong>Cliente:</strong>
                                                            <span>{{ userdata.cliente?.nombre }}</span>
                                                        </li>
                                                        <li>
                                                            <strong>Dirección:</strong>
                                                            <span>
                                                                {{ userdata.cliente?.direccion }} <br>
                                                                {{ userdata.cliente?.cp }} - {{ userdata.cliente?.poblacion }}<br>
                                                                {{ userdata.cliente?.provincia }}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div style="width: 48%;">
                                                    <ul>
                                                        <li>
                                                            <strong>Contacto:</strong>
                                                            <span>{{ userdata.cliente?.contacto }}</span>
                                                        </li>
                                                        <li>
                                                            <strong>Teléfono:</strong>
                                                            <span>{{ userdata.cliente?.telefono }}</span>
                                                        </li>
                                                        <li>
                                                            <strong>eMail:</strong>
                                                            <span>{{ userdata.cliente?.email }}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>




                            </div>




                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>