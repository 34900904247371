import { Component, Input } from '@angular/core';
import { MaterialModule } from 'app/auth/material/material.module';

// interfaces
import { IEmail } from 'app/interfaces/email';

// servicios
import { UsuarioService } from 'app/services/datos/usuario.service';
import { EmailService } from 'app/services/mail.service';
import { NotificacionesService } from 'app/services/notificaciones.service';

// librerías
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cambiar-psw',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './cambiar-psw.component.html',
  styleUrl: './cambiar-psw.component.scss'
})
export class CambiarPswComponent {

  @Input() public loading: boolean = false;

  constructor(
    private eMailSrv: EmailService,
    private userSrv: UsuarioService,
    public notificSrv: NotificacionesService
  ) { }
  cambiarPsw() {

    const timestamp: number = new Date().getTime();
    const data: IEmail = {
      'toEmail': this.userSrv.userdata.email!,
      'subject': `Solicitud de cambio de contraseña ${timestamp}`,
      'body': 'body del eMail desde cambiarPsw() ---Método no implementado---',
    }

    this.loading = true;
    this.eMailSrv.enviaEmail(data).subscribe(resp => {
      this.loading = false;
      console.log('enviaEmail()', resp);

      if (resp.error) {
        this.notificSrv.aviso('error', resp.mensaje);
        return
      }

      Swal.fire({
        title: 'Cambiar contraseña',
        icon: 'info',
        html: `Te hemos enviado un email a <b>${this.userSrv.userdata.email}</b> con las instrucciones para cambiar la contrseña.<br><br>
       Revisa tu bandeja de Spam, igual ha ido a parar ahí`,
      })


    })

  }
}

