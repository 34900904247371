/* eslint-disable @angular-eslint/use-lifecycle-interface */

// eslint-disable-next-line @typescript-eslint/no-unused-vars 

import { Component } from '@angular/core';

// módulos
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaterialModule } from '../material/material.module';

// pipes
import { FriendlyTimestampPipe } from '../../pipes/friendly-timestamp.pipe';

// servicios
import { UsuarioService } from '../../services/datos/usuario.service';
import { NotificacionesService } from '../../services/notificaciones.service';
import { AvisosService } from 'app/services/datos/avisos.service';
import { IListaOpciones } from 'app/interfaces/lista-opciones';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule, MatFormFieldModule, MatInputModule, FormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})


export class LoginComponent {
  hidePsw: boolean = true;
  loading: boolean = false;
  public enviado: boolean = false;

  public aviso: string = '';
  public error: string = '';

  constructor(
    private avisosSrv: AvisosService,
    private router: Router,
    private fb: FormBuilder,
    public usuarioSrv: UsuarioService,
    private notificacionSrv: NotificacionesService,

  ) { }
  public loginForm = this.fb.group({
    username: [localStorage.getItem('username') || '', [Validators.required]],
    password: ['', Validators.required],
    remember: [localStorage.getItem('recordar') === 'true' || false]
  })


  ngOnInit(): void {
    // logOut
    localStorage.removeItem('token');
  }


  enviar() {
    this.enviado = true;
    this.aviso = '';
    this.error = '';

    const frm = this.loginForm;
    this.loading = true;
    this.usuarioSrv.logIn(frm.value)
      .subscribe(resp => {
        this.loading = false;

        console.log('__logIn', resp)

        if (resp.error) {
          this.error = resp.mensaje;
          this.loginForm.get('username')!.setErrors({ apiError: this.error });
          this.loginForm.get('password')!.setErrors({ apiError: this.error });

          if (typeof this.error === 'object') {
            // más de 1 mensaje 
            const valores: string[] = Object.values(this.error);
            this.error = valores[0];
          }
        } else {
          let mensajePiped: string = '';
          if (this.usuarioSrv.userdata.timestamp) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            mensajePiped = '<br>Tu última conexión fue hace ' + new FriendlyTimestampPipe().transform(this.usuarioSrv.userdata.timestamp!);
          }
          this.notificacionSrv.aviso('info', `${this.obtenerSaludo()} ${resp.data.userdata.nombre!.toUpperCase()}`);
          if (frm.get('remember')?.value) {
            localStorage.setItem('username', frm.get('username')!.value!);
            localStorage.setItem('recordar', 'true');
          } else {
            localStorage.removeItem('username');
            localStorage.removeItem('recordar');
          }

          if (this.usuarioSrv.userdata.rol != "cliente") {
            this.aviso = `Tu rol ${this.usuarioSrv.userdata.rol} NO te permite acceder a esta Intranet`;
            return
          }

          if (this.usuarioSrv.userdata.cliente?.acceso_intranet != "1") {
            this.error = `Sin acceso a esta Intranet, ponte en contacto con el administrador`;
            return
          }

          // Access granted //


          // Buscar avisos nuevos
          this.loading = true;
          const data: IListaOpciones = {
            'id_cliente': this.usuarioSrv.userdata.cliente.id
          }
          this.avisosSrv.getAvisosLista(data)
            .subscribe(resp => {
              this.loading = false;

              if (resp.error) {
                this.notificacionSrv.aviso('error', resp.mensaje)
              }

              if (resp.totales.noLeidos) {
                const noLeidos = resp.totales.noLeidos;
                const mensaje = `Tienes ${noLeidos} aviso${noLeidos !== 1 ? 's' : ''} sin leer`;
                this.notificacionSrv.aviso('success', `<i class="fas fa-bell"></i> ${mensaje}`);
              }

              // this.usuarioSrv.userdata.avisos = resp.data;
              // this.usuarioSrv.userdata.avisos!.totales = resp.totales;
              console.log('__LogIn() userdata= ', this.usuarioSrv.userdata);

            });

          this.router.navigateByUrl('/');

        }
      })
  }


  recordarPsw() {

    Swal.fire({
      title: 'Recordar contraseña',
      input: "text",
      inputAttributes: {
        autocapitalize: 'off'
      },
      icon: 'question',
      html: 'No te preocupes, a todos nos pasa🙄.<br><br> Introduce tu dirección de eMail, si existe en nuestra base de datos, te enviaremos las instrucciones para cambiarla',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Enviar',
    }).then((result) => {
      if (result.isConfirmed) {
        const email = result.value;

        console.log('Confirmado acción, result ', result);

        if (email) {
          this.notificacionSrv.aviso('warning', `recordarPsw(), Este método no está implementado`);
          this.notificacionSrv.aviso('info', `Se pide pswordReset para ${email}`);
          // Aquí continuar con el código para enviar el email
        }
      }
    });
  }



  // AUX  //

  get f() {
    return this.loginForm.controls;
  }


  togglePasswordVisibility() {
    this.hidePsw = !this.hidePsw;
  }


  obtenerSaludo(): string {
    const horaActual = new Date().getHours();

    if (horaActual >= 5 && horaActual < 12) {
      return 'Buenos días';
    } else if (horaActual >= 12 && horaActual < 20) {
      return 'Buenas tardes';
    } else {
      return 'Buenas noches';
    }
  }





}
