import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'; // Angular Material 

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    constructor(
        private dialog: MatDialog,
    ) { }

    cerrarTodosLosModalesMaterial() {
        // Cierra todos los diálogos de Angular Material
        this.dialog.closeAll();
    }
}
